import { RootState } from "@/redux";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import styled from "styled-components";
import { Program, SurveyStep } from "@/models/program";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { Survey as SubmitSurvey } from "@/redux/survey";
import SurveyForm from "./SurveyForm";
import getSurvey from "@/db/survey/getSurvey";
import usePreview from "@/hooks/usePreview";
import useOwner from "@/hooks/useOwner";
import useSessionId from "@/hooks/useSessionId";
import useUid from "@/hooks/useUid";
import { colors } from "@/lib/styles";
import useEmbeddedProgram from "@/hooks/useEmbeddedProgram";
import useProgram from "@/hooks/useProgram";
import useSessionIndex from "@/hooks/useSessionIndex";
import useGroup from "@/hooks/useGroup";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  flex: 1;
  width: 100%;
  margin: auto;
  overflow-y: auto;
  border-radius: 10px;
  border: 1px solid ${colors.lightGray};
  padding: 0 10px;
`;

type Props = {
  program: Program;
  step: SurveyStep;
  setDone: (done: boolean) => void;
} & ConnectedProps<typeof connector>;

const Survey: React.FC<Props> = (props) => {
  const { step, setDone, program } = props;

  const preview = usePreview();
  const org = useOwner();
  const uid = useUid();
  const group = useGroup();
  const sessionId = useSessionId();
  const sessionIndex = useSessionIndex();
  const programCtx = useProgram();
  const embeddedProgram = useEmbeddedProgram();
  const activeStepId = programCtx?.steps[sessionIndex].stepId;

  const Form = SurveyForm(
    program.id,
    step.surveyId,
    sessionId,
    embeddedProgram ? activeStepId : undefined
  );

  const surveyQuery = useQuery(
    [`survey`, step.surveyId, sessionId, activeStepId],
    sessionId
      ? async () =>
          await getSurvey({
            surveyId: step.surveyId,
            userId: uid,
            sessionId: embeddedProgram ? undefined : sessionId,
            embeddedSessionId: embeddedProgram ? sessionId : undefined,
            embeddedStepId: activeStepId,
          })
      : null,
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setDone(!!surveyQuery?.data?.questions || preview);
  }, [surveyQuery?.data]);

  const submit = async () => {
    await props.SubmitSurvey(
      program,
      step,
      group?.id,
      sessionId,
      embeddedProgram ? activeStepId : undefined
    );
  };

  const initialValues =
    surveyQuery.data && surveyQuery.data.questions
      ? { questions: surveyQuery.data.questions }
      : undefined;

  console.log("surveyQuery", surveyQuery?.data);

  return (
    <>
      <Wrapper>
        <Form
          step={step}
          onSubmit={submit}
          onSubmitSuccess={surveyQuery.refetch}
          onSubmitFail={() => surveyQuery.refetch()}
          initialValues={initialValues}
          survey={surveyQuery?.data}
        />
      </Wrapper>
    </>
  );
};

const mapState = (state: RootState) => ({});
const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators({ SubmitSurvey }, dispatch);
const connector = connect(mapState, mapDispatch);

export default React.memo(connector(Survey));
