import { SurveyStep } from "@/models/program";
import { SurveyResponse } from "@/models/survey";
import { find } from "lodash";
import * as Sentry from "@sentry/nextjs";
import { colors } from "./styles";

export const roundedAverage = (a: number[]): number => {
  const sum = a.reduce((acc, val) => acc + val, 0);
  const avg = sum / a.length;
  return Math.round(avg * 100) / 100;
};

export const colorScore = (score: number): string => {
  if (score <= 2) return colors.lightRed;
  if (score <= 3) return colors.lightYellow;
  else return colors.lightGreen;
};

export const colorPercentageChange = (change: number): string => {
  if (change > 0) return colors.lightGreen;
  else return colors.lightGray;
};

export const calculateScore = (
  surveyStep: SurveyStep,
  surveyResponse: SurveyResponse
): { score: number; total: number; correct: number } => {
  try {
    if (!surveyStep || !surveyResponse) return null;
    let score: number | null = null;
    let correct = 0;
    let total = 0;
    if (surveyStep?.surveyType === "QUIZ") {
      // if QUIZ, calculate the score
      surveyStep.questions.forEach((surveyQuestion) => {
        total++;
        if (surveyQuestion?.type === "MULTIPLE_CHOICE") {
          if (surveyQuestion?.selectMultiple) {
            const correctOptionIds = JSON.stringify(
              surveyQuestion?.options
                .filter((o) => o.correct)
                .map((o) => o.optionId)
                .sort((a, b) => (b > a ? 1 : -1))
            );
            const selectedOptionIds = JSON.stringify(
              (
                surveyResponse?.questions[
                  surveyQuestion?.questionId
                ] as string[]
              ).sort((a, b) => (b > a ? 1 : -1))
            );

            if (correctOptionIds === selectedOptionIds) correct++;
          } else {
            const correctAnswer = find(surveyQuestion?.options, {
              correct: true,
            });
            if (!correctAnswer) {
              correct++;
            } else if (
              surveyResponse?.questions &&
              surveyResponse?.questions[surveyQuestion?.questionId] ===
                correctAnswer?.optionId
            )
              correct++;
          }
        }
        if (surveyQuestion?.type === "SHORT_ANSWER") correct++;
      });
      if (total) score = correct / total;
      return {
        score,
        total,
        correct,
      };
    } else return null;
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);
    return null;
  }
};
