import styled from "styled-components";
import SideBar from "@/components/Session/SideBar";
import Step from "@/components/Session/Step";
import { useRouter } from "next/router";
import Progress from "../universal/Progress";
import useWarnBeforeLeave from "@/hooks/useWarnBeforeLeave";
import useOwner from "@/hooks/useOwner";
import useProgram from "@/hooks/useProgram";
import useSessionStepIndex from "@/hooks/useSessionIndex";
import usePreview from "@/hooks/usePreview";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

type Props = {};

export const ProgramComponent: React.FC<Props> = (props) => {
  const {} = props;

  const preview = usePreview();
  const org = useOwner();
  const program = useProgram();
  const stepIndex = useSessionStepIndex();
  const router = useRouter();
  const sessionId = router.query.session;

  useWarnBeforeLeave(
    !!sessionId &&
      stepIndex !== 0 &&
      !(stepIndex >= program?.steps.length - 1) &&
      !preview,
    "Are you sure you want to leave? Your progress may not be saved"
  );

  return (
    <Wrapper>
      {program.steps.length > 1 && <SideBar />}
      <Step />
      <Progress progress={stepIndex / program?.steps.length} />
    </Wrapper>
  );
};

export default ProgramComponent;
