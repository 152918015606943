import { sessionSelectors } from "@/redux/session";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import usePreview from "./usePreview";
import useSession from "./useSession";
import useSessionStepIndex from "./useSessionIndex";

const useActiveSessionStep = () => {
  const session = useSession();
  const stepIndex = useSessionStepIndex();
  return session?.steps[stepIndex] || null;
};
export default useActiveSessionStep;
