import { ChatStep } from "@/models/program";
import React from "react";
import BaseChat from "./Base";
import TutorChat from "./Tutor";

type Props = {
  step: ChatStep;
  setDone: (done: boolean) => void;
};

export const Chat: React.FC<Props> = (props) => {
  const { step } = props;

  return (
    <>
      {step.subType === "BASE" && <BaseChat {...props} />}
      {step.subType === "TUTOR" && <TutorChat {...props} />}
    </>
  );
};

export default React.memo(Chat);
