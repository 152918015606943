import { colors } from "@/lib/styles";

import styled from "styled-components";
import { VideoStep } from "@/models/program";
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import PlayIcon from "@/components/icons/PlayIcon";
import { MOBILE_WIDTH } from "@/lib/constants";
import { ownerColor } from "@/lib/owner";
import useOwner from "@/hooks/useOwner";
import useActiveSessionStep from "@/hooks/useActiveSessionStep";
import MuxPlayer from "@mux/mux-player-react";
import useUid from "@/hooks/useUid";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  flex: 1;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    padding: 15px;
  }
`;

const Control = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid ${colors.lightGray};
  border-radius: 10px;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    border: none;
  }
`;

const PlayButton = styled.button`
  padding: 20px 30px;
  outline: none;
  border-radius: 10px;
  border: none;
  font-size: 28px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 21px;
    padding: 10px 20px;
  }
`;

const DonePlayButton = styled.button`
  border-radius: 30px;
  background-color: ${colors.nearWhitePrimary};
  padding: 20px 30px;
  font-size: 28px;
  font-weight: 600;
  outline: none;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 21px;
    padding: 10px 20px;
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  pointer-events: auto;
  border-radius: 10px;
  overflow: hidden;
  max-height: calc(100vh - 220px);
`;

type OwnProps = {
  step: VideoStep;
  setDone: (done: boolean) => void;
};
type Props = OwnProps;

const Video: React.FC<Props> = (props) => {
  const { step, setDone } = props;
  const uid = useUid();

  const owner = useOwner();
  const activeSessionStep = useActiveSessionStep();
  const completed = !!activeSessionStep?.completedTime;

  const [playing, setPlaying] = useState(false);
  useEffect(() => {
    if (completed) setDone(completed);
  }, [completed]);

  const player = useRef(null);

  const handleKey = ({ key }) => {
    if (key === " ") {
      if (playing) setPlaying(false);
      else start();
    }
  };

  // listen for spacebar to toggle play/pause
  useEffect(() => {
    window.addEventListener("keydown", handleKey);
    return () => window.removeEventListener("keydown", handleKey);
  }, []);

  const start = () => {
    setPlaying(true);
  };

  const end = () => {
    setPlaying(false);
    setDone(true);
  };

  return (
    <>
      <Wrapper>
        {!step.playbackId && (
          <VideoWrapper onClick={playing ? () => setPlaying(false) : start}>
            {/* @ts-ignore */}
            <ReactPlayer
              url={step.video}
              playing={playing}
              width="100%"
              height="100%"
              ref={player}
              onEnded={end}
              style={{ pointerEvents: "none" }}
              config={{
                youtube: {
                  playerVars: {
                    disablekb: 1,
                    controls: 0,
                  },
                },
              }}
            />

            {!playing && !completed && (
              <Control>
                <PlayButton
                  style={{ backgroundColor: ownerColor(owner) }}
                  onClick={start}
                  data-cy={"play-video"}
                >
                  <PlayIcon
                    color={colors.white}
                    fill={colors.white}
                    size={25}
                    style={{ marginRight: 15, marginTop: 5 }}
                  />
                  Play video
                </PlayButton>
              </Control>
            )}
            {!playing && completed && (
              <Control>
                <DonePlayButton
                  style={{ color: ownerColor(owner) }}
                  onClick={start}
                >
                  <PlayIcon
                    color={ownerColor(owner)}
                    fill={ownerColor(owner)}
                    size={30}
                    style={{ marginRight: 15, marginTop: 5 }}
                  />
                  Replay video
                </DonePlayButton>
              </Control>
            )}
          </VideoWrapper>
        )}
        {!!step.playbackId && (
          <MuxPlayer
            streamType="on-demand"
            playbackId={step.playbackId}
            onEnded={() => setDone(true)}
            metadata={{
              video_title: step.title,
              video_user_id: uid,
            }}
            style={{
              maxHeight: "calc(100vh - 220px)",
            }}
          />
        )}
      </Wrapper>
    </>
  );
};

export default Video;
