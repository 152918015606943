import { colors } from "@/lib/styles";

import styled from "styled-components";
import { PdfStep } from "@/models/program";
import { MAX_WIDTH, MOBILE_WIDTH } from "@/lib/constants";
import PdfViewer from "@/components/universal/PdfViewer";
import useWindowSize from "@/hooks/useWindowSize";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  flex: 1;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    padding: 15px;
  }
`;

const PdfWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid ${colors.lightGray};
  border-radius: 10px;
  width: 100%;
  flex: 1;
  max-height: calc(100vh - 220px);
  overflow-y: scroll;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    border: none;
  }
`;

type Props = {
  step: PdfStep;
  setDone: (done: boolean) => void;
};

const Pdf: React.FC<Props> = (props) => {
  const { step, setDone } = props;

  const { width } = useWindowSize();

  const pageWidth =
    width > MOBILE_WIDTH ? Math.min(width - 380, MAX_WIDTH - 40) : width - 40;

  return (
    <>
      <Wrapper>
        <PdfWrapper>
          <PdfViewer
            width={pageWidth}
            url={step.pdf}
            done={() => {
              setTimeout(() => {
                setDone(true);
              }, 1000);
            }}
          />
        </PdfWrapper>
      </Wrapper>
    </>
  );
};

export default Pdf;
