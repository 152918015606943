/* eslint-disable react/display-name */

import useOwnerColor from "@/hooks/useOwnerColor";
import { colors } from "@/lib/styles";
import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

export default function StyledMarkdown({
  text,
  color,
}: {
  text: string;
  color?: string;
}) {
  const ownerColor = useOwnerColor();
  return (
    <ReactMarkdown
      components={{
        code: (props) => <StyledCode color={color} {...props} />,
        a: (props) => (
          <StyledLink color={ownerColor} target="_blank" {...props} />
        ),
        h1: (props) => <StyledH1 color={color} {...props} />,
        h2: (props) => <StyledH2 color={color} {...props} />,
        h3: (props) => <StyledH3 color={color} {...props} />,
        h4: (props) => <StyledH4 color={color} {...props} />,
        h5: (props) => <StyledH5 color={color} {...props} />,
        h6: (props) => <StyledH6 color={color} {...props} />,
        li: (props) => <StyledLi color={color} {...props} />,
        ol: (props) => <StyledOl color={color} {...props} />,
        td: (props) => <StyledTd color={color} {...props} />,
        th: (props) => <StyledTh color={color} {...props} />,
        tr: (props) => <StyledTr color={color} {...props} />,
        ul: (props) => <StyledUl color={color} {...props} />,
        strong: (props) => <StyledStrong color={color} {...props} />,
        em: (props) => <StyledEm color={color} {...props} />,
        p: (props) => <StyledP color={color} {...props} />,
      }}
    >
      {text}
    </ReactMarkdown>
  );
}

const StyledCode = styled.code`
  font-size: 16px;
  font-weight: 400;
  background-color: ${colors.nearWhite};
  border-radius: 20px;
`;

const StyledLink = styled.a<{ color: string }>`
  color: ${(props) => props.color};
  text-decoration: underline;
  &:hover {
    opacity: 0.8;
  }
`;

const StyledH1 = styled.h1`
  font-size: 28px;
  font-weight: 600;
  line-height: 1.5em;
  margin-top: 20px;
  margin-bottom: 15px;
`;

const StyledH2 = styled.h2`
  font-size: 21px;
  font-weight: 600;
  line-height: 1.5em;
  margin-top: 15px;
  margin-bottom: 12px;
`;

const StyledH3 = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5em;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const StyledH4 = styled.h4`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5em;
`;

const StyledH5 = styled.h5`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5em;
`;

const StyledH6 = styled.h6`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5em;
`;

const StyledLi = styled.li`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5em;
  margin: 5px 0;
  @media screen and (max-width: MOBILE_WIDTH) {
    margin: 10px 0 0;
  }
`;

const StyledOl = styled.ol`
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 15px;
  padding-right: 15px;
  list-style-type: decimal;
  margin-bottom: 0px;
  @media screen and (max-width: MOBILE_WIDTH) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

const StyledUl = styled.ul`
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 15px;
  padding-right: 15px;
  @media screen and (max-width: MOBILE_WIDTH) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

const StyledP = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5em;
`;

const StyledTd = styled.td``;

const StyledTh = styled.th``;

const StyledTr = styled.tr``;

const StyledStrong = styled.strong`
  font-weight: 600;
`;

// source inline citation
const StyledEm = styled.em`
  font-style: italic;
`;
