import { colors } from "@/lib/styles";
import Link from "next/link";
import { rgba } from "polished";
import styled, { css } from "styled-components";
import CaretIcon from "../icons/CaretIcon";

type Props = {
  color: string;
  disabled?: boolean;
  href?: string;
  title: string;
  style?: Record<string, unknown>;
  onClick?: () => void;
};

const style = (props: Props) => css<Props>`
  padding: 10px 15px;
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  border: none;
  border-radius: 10px;
  border: 1px solid
    ${(props) => (props.disabled ? colors.lightGray : colors.lightGray)};
  background-color: ${(props) => (props.disabled ? colors.white : props.color)};
  color: ${(props) => (props.disabled ? colors.gray : colors.white)};
  font-weight: 600;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  box-shadow: rgba(255, 255, 255, 0.5) 0 1px 5px 0;
  outline: none;
  text-decoration: none;
  &:hover {
    border: 1px solid ${(props) => (props.disabled ? colors.gray : props.color)};
    background-color: ${(props) =>
      props.disabled ? colors.white : rgba(props.color, 0.9)};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
`;
const StyledButton = styled.button<Props>`
  ${style}
`;

const StyledAnchor = styled.a<Props>`
  ${style}
`;

const NextButton: React.FC<Props> = (props) => {
  if (props.href)
    return (
      <Link href={props.href}>
        <StyledAnchor {...props}>
          {props.title}
          <CaretIcon
            color={props.disabled ? colors.gray : colors.white}
            size={24}
            style={{ marginLeft: 10, marginTop: 5 }}
          />
        </StyledAnchor>
      </Link>
    );
  else
    return (
      <StyledButton {...props}>
        {props.title}
        <CaretIcon
          color={props.disabled ? colors.gray : colors.white}
          size={24}
          style={{ marginLeft: 10, marginTop: 5 }}
        />
      </StyledButton>
    );
};
export default NextButton;
