import styled from "styled-components";
import { Step } from "@/models/program";
import useEmbeddedProgram from "@/hooks/useEmbeddedProgram";
import useEmbeddedSessionIndex from "@/hooks/useEmbeddedSessionIndex";
import { colors } from "@/lib/styles";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;

const Title = styled.h1`
  font-size: 21px;
  font-weight: 600;
  margin: 0px 0 20px;
`;

const Slash = styled.span`
  margin: 0 10px;
  font-weight: 500;
  font-size: 21px;
  color: ${colors.gray};
`;

type Props = {
  step: Step;
};

export const StepHeader: React.FC<Props> = ({ step }) => {
  const embeddedProgram = useEmbeddedProgram();
  const embeddedSessionIndex = useEmbeddedSessionIndex();

  return (
    <>
      <Wrapper>
        <Title>
          {step.title}
          {embeddedProgram && <Slash>/</Slash>}
          {embeddedProgram
            ? `${embeddedProgram?.steps[embeddedSessionIndex]?.title}`
            : ""}
        </Title>
      </Wrapper>
    </>
  );
};

export default StepHeader;
