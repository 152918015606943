import { firestore } from "@/firebase";
import moment from "moment";
import * as Sentry from "@sentry/nextjs";
import log from "../utils/log";
import { Session } from "@/models/session";
import { Group } from "@/models/group";
import { Program } from "@/models/program";

const completeSession = async (data: {
  sessionId: string;
}): Promise<string | null> => {
  const { sessionId } = data;
  try {
    const sessionDoc = await firestore
      .collection("sessions")
      .doc(sessionId)
      .get();
    if (sessionDoc.exists) {
      // mark session as completed
      sessionDoc.ref
        .update({
          completedTime: moment().unix(),
        })
        .then(() => {
          console.log("Session document updated");
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.log(error);
        });
      // retrieve session, program, and group
      const session = sessionDoc.data() as Session;
      firestore
        .collection("programs")
        .doc(session.programId)
        .get()
        .then(async (doc) => {
          const program = doc.data() as Program;
          const group = session.groupId
            ? ((
                await firestore
                  .collection("orgs")
                  .doc(session.orgId)
                  .collection("groups")
                  .doc(session.groupId)
                  .get()
              ).data() as Group)
            : null;
          // log session into slack
          log(
            "session",
            group ? `_${group?.title}_ ${program.title}` : program.title
          )()
            .then(() => {
              console.log("Session logged");
            })
            .catch((error) => {
              Sentry.captureException(error);
              console.log(error);
            });
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.log(error);
        });

      return null;
    } else throw new Error("Could not retrieve session");
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);
  }
};

export default completeSession;
