import styled from "styled-components";
import NextButton from "@/components/universal/NextButton";
import previousSessionStep from "@/db/session/previousSessionStep";
import nextSessionStep from "@/db/session/nextSessionStep";
import BackButton from "@/components/universal/Button/BackButton";
import { useMutation } from "react-query";
import { ownerColor } from "@/lib/owner";
import useOwner from "@/hooks/useOwner";
import useActiveSessionStep from "@/hooks/useActiveSessionStep";
import useSessionId from "@/hooks/useSessionId";
import usePreview from "@/hooks/usePreview";
import useSession from "@/hooks/useSession";
import useAdmin from "@/hooks/useAdmin";
import useProgram from "@/hooks/useProgram";
import useEmbeddedProgram from "@/hooks/useEmbeddedProgram";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;

type Props = {
  done: boolean;
};

const Actions: React.FC<Props> = (props) => {
  const { done } = props;

  const owner = useOwner();
  const admin = useAdmin();
  const nextSessionStepMutation = useMutation(nextSessionStep);
  const previousSessionStepMutation = useMutation(previousSessionStep);
  const sessionId = useSessionId();
  const preview = usePreview();
  const program = useProgram();
  const session = useSession();
  const embeddedProgram = useEmbeddedProgram();
  const completed = useActiveSessionStep()?.completedTime;

  return (
    <>
      <Wrapper>
        <NextButton
          color={ownerColor(owner)}
          title="Next"
          onClick={() =>
            nextSessionStepMutation.mutate({
              owner,
              sessionId,
              admin,
              program,
              embeddedProgram,
            })
          }
          disabled={preview ? false : !done && !completed}
          data-cy={`next-step`}
        />
        {!(session?.activeIndex === 0) && (
          <BackButton
            title="Back"
            onClick={() =>
              previousSessionStepMutation.mutate({
                owner,
                sessionId,
                admin,
                program,
                embeddedProgram,
              })
            }
          />
        )}
      </Wrapper>
    </>
  );
};

export default Actions;
