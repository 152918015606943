import styled from "styled-components";
import { EmbeddedProgramStep, Step } from "@/models/program";
import React from "react";
import useEmbeddedProgram from "@/hooks/useEmbeddedProgram";
import Text from "../../Text/Session";
import Chat from "../../Chat/Session/Base";
import Section from "../../Section/Session";
import Survey from "../../Survey/Session";
import ImageComponent from "../../Image/Session";
import Video from "../../Video/Session";
import Pdf from "../../Pdf/Session";
import useEmbeddedSessionIndex from "@/hooks/useEmbeddedSessionIndex";

const Loading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  flex: 1;
`;

type Props = {
  step: EmbeddedProgramStep;
  setDone: (done: boolean) => void;
};

export const EmbeddedProgram: React.FC<Props> = (props) => {
  const { step, setDone } = props;

  const embeddedProgram = useEmbeddedProgram();
  const embeddedSessionIndex = useEmbeddedSessionIndex();

  const StepComponent = (programStep: Step) => {
    switch (programStep?.type) {
      case "CHAT":
        return (
          <Chat
            key={`chat-step-${programStep?.stepId}`}
            step={programStep}
            setDone={setDone}
          />
        );
      case "TEXT":
        return (
          <Text
            key={`text-step-${programStep?.stepId}`}
            step={programStep}
            setDone={setDone}
          />
        );
      case "IMAGE":
        return (
          <ImageComponent
            key={`image-step-${programStep?.stepId}`}
            setDone={setDone}
            step={programStep}
          />
        );
      case "VIDEO":
        return (
          <Video
            key={`text-step-${programStep?.stepId}`}
            setDone={setDone}
            step={programStep}
          />
        );
      case "PDF":
        return (
          <Pdf
            key={`text-step-${programStep?.stepId}`}
            setDone={setDone}
            step={programStep}
          />
        );
      case "SURVEY":
        return (
          <Survey
            key={`text-step-${programStep?.stepId}`}
            setDone={setDone}
            step={programStep}
            program={embeddedProgram}
          />
        );
      case "SECTION":
        return (
          <Section
            key={`text-step-${programStep?.stepId}`}
            step={programStep}
            setDone={setDone}
          />
        );
      default:
        return <Loading key={`default-step`} />;
    }
  };

  return StepComponent(embeddedProgram?.steps?.[embeddedSessionIndex || 0]);
};
export default EmbeddedProgram;
