import { colors } from "@/lib/styles";
import Link from "next/link";
import styled, { css } from "styled-components";
import CaretIcon from "../../icons/CaretIcon";

type Props = {
  href?: string;
  title: string;
  style?: Record<string, unknown>;
  onClick?: () => void;
};

const style = (props: Props) => css<Props>`
  padding: 10px 15px;
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  border: none;
  background-color: ${colors.white};
  color: ${colors.darkGray};
  border: 1px solid ${colors.lightGray};
  font-weight: 600;
  border-radius: 10px;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  box-shadow: rgba(255, 255, 255, 0.5) 0 1px 5px 0;
  outline: none;
  text-decoration: none;
  &:hover {
    border: 1px solid ${colors.nearBlack};
    color: ${colors.nearBlack};
    background-color: ${colors.nearWhite};
    cursor: pointer;
    path {
      stroke: ${colors.nearBlack};
    }
  }
`;
const StyledButton = styled.button<Props>`
  ${style}
`;

const StyledAnchor = styled.a<Props>`
  ${style}
`;

const BackButton: React.FC<Props> = (props) => {
  if (props.href)
    return (
      <Link href={props.href}>
        <StyledAnchor {...props}>
          <CaretIcon
            color={colors.darkGray}
            size={24}
            style={{
              marginRight: 10,
              marginBottom: 5,
              transform: "rotate(180deg)",
            }}
          />
          {props.title}
        </StyledAnchor>
      </Link>
    );
  else
    return (
      <StyledButton {...props}>
        <CaretIcon
          color={colors.darkGray}
          size={24}
          style={{
            marginRight: 10,
            marginBottom: 5,
            transform: "rotate(180deg)",
          }}
        />
        {props.title}
      </StyledButton>
    );
};
export default BackButton;
