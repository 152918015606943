import { firestore } from "@/firebase";
import * as Sentry from "@sentry/nextjs";
import { Session } from "@/models/session";
import moment from "moment";
import getSession from "./getSession";
import addMembership from "../membership/addMembership";
import getVersion from "../version/getVersion";
import startSessionStep from "./startSessionStep";
import getProgram from "../program/getProgram";
import startSurvey from "../survey/startSurvey";

// starts session and returns session id
const startSession = async (data: {
  programId: string;
  orgId: string;
  userId: string;
  embeddedSessionId?: string;
  embeddedStepId?: string;
}): Promise<Session> => {
  try {
    const { programId, userId, orgId, embeddedSessionId, embeddedStepId } =
      data;
    console.log("SHOULD START SESSION", data);

    if (!programId && !userId && !orgId)
      throw new Error("Cannot start session with program, org, and user ids");

    // try to get existing session
    const existingSession = await getSession({
      userId,
      programId,
      embeddedSessionId,
      embeddedStepId,
    })();
    if (existingSession && !existingSession.completedTime) {
      return existingSession;
    }

    const { majorVersion, minorVersion } = await getVersion(orgId)();

    // if no unfinished existing session => create new session
    let session: Session = {
      id: "PENDING",
      userId,
      programId,
      minorVersion,
      majorVersion,
      activeIndex: 0,
      orgId,
      steps: [],
      startedTime: moment().unix(),
    };

    if (embeddedSessionId) session.embeddedSessionId = embeddedSessionId;
    if (embeddedStepId) session.embeddedStepId = embeddedStepId;
    console.log("SHOULD ADD SESSION", session);

    const sessionDoc = await firestore.collection("sessions").add(session);

    await sessionDoc.update({ id: sessionDoc.id });

    console.log("ADDED SESSION", sessionDoc.id);

    await startSessionStep({
      sessionId: sessionDoc.id,
      stepIndex: 0,
    });

    console.log("STARTED SESSION STEP", sessionDoc.id);

    const program = await getProgram(programId)();
    if (program?.steps?.[0]?.type === "SURVEY") {
      await startSurvey({
        orgId,
        programId,
        userId,
        sessionId: sessionDoc.id,
        surveyId: program?.steps?.[0]?.surveyId,
        embeddedSessionId,
        embeddedStepId,
      });
    }

    console.log("STARTED SURVEY", sessionDoc.id);

    return { ...session, id: sessionDoc.id } as Session;
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);
    throw error;
  }
};

export default startSession;
