import useOwner from "@/hooks/useOwner";
import { ownerColor } from "@/lib/owner";
import React from "react";
import styled from "styled-components";

const ProgressBarOuter = styled.div<{ color: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: ${(props) => props.color};
  opacity: 20%;
`;

const ProgressBarInner = styled.div<{ color: string; width: number }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  width: ${(props) => 5 + props.width * 95}vw;
  background-color: ${(props) => props.color};
`;

type Props = {
  progress: number;
  style?: Record<string, unknown>;
};

const Progress: React.FC<Props> = (props) => {
  const { progress, style } = props;

  const owner = useOwner();

  return (
    <>
      <ProgressBarOuter color={ownerColor(owner)} />
      <ProgressBarInner width={progress} color={ownerColor(owner)} />
    </>
  );
};

export default Progress;
