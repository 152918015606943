import { useRouter } from "next/router";
import usePreview from "./usePreview";
import useSession from "./useSession";

const useEmbeddedSessionIndex = () => {
  const session = useSession();
  const preview = usePreview();
  const router = useRouter();
  let stepIndex = preview
    ? router.query.i
      ? Number(router.query.i)
      : 0
    : session?.activeIndex || 0;
  const [parentStepIndex, embeddedStepIndex] = String(stepIndex).split(".");
  return Number(embeddedStepIndex) || 0;
};
export default useEmbeddedSessionIndex;
