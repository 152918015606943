import { colors } from "@/lib/styles";

import styled from "styled-components";
import { SectionStep } from "@/models/program";
import { useRouter } from "next/router";
import { MOBILE_WIDTH } from "@/lib/constants";
import Image from "next/image";
import { useEffect } from "react";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  flex: 1;
  border: 1px solid ${colors.lightGray};
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 30px 40px;
  margin: 15px;
`;

const Title = styled.h1`
  font-size: 50px;
  font-weight: 600;
  color: ${colors.white};
  margin: 0;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 21px;
  }
`;

type Props = {
  step: SectionStep;
  setDone: (done: boolean) => void;
};

export const Section: React.FC<Props> = (props) => {
  const { step, setDone } = props;

  const router = useRouter();

  useEffect(() => {
    setTimeout(() => {
      setDone(true);
    }, 1000);
  }, []);

  return (
    <>
      <Wrapper>
        {step.image && (
          <BackgroundImage>
            <Image layout="fill" objectFit="cover" src={step.image} />
          </BackgroundImage>
        )}
        <TitleWrapper>
          <Title>{step.title}</Title>
        </TitleWrapper>
      </Wrapper>
    </>
  );
};

export default Section;
