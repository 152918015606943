import React from "react";
import styled from "styled-components";
import { colors } from "@/lib/styles";
import CheckIcon from "@/components/icons/CheckIcon";
import { MOBILE_WIDTH } from "@/lib/constants";
import CrossIcon from "@/components/icons/CrossIcon";
import { ownerColor } from "@/lib/owner";
import useOwner from "@/hooks/useOwner";

/*
Multi Select Input
Select from a number of options
*/

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-size: 21px;
  font-weight: 500;
  color: ${colors.nearBlack};
  margin-right: 10px;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 12px;
  }
`;

const Error = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${colors.darkGray};
  margin-top: 5px;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 12px;
  }
`;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 0;
  width: 100%;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Option = styled.div<{
  color: string;
  checked: boolean;
  wrong: boolean;
  right: boolean;
}>`
  user-select: none;
  font-size: 21px;
  font-weight: 500;
  color: ${(props) =>
    props.wrong ? colors.red : props.right ? props.color : colors.nearBlack};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 20px 5px 0;
  margin: 3px 0 3px 0;
  flex-basis: 50%;
  /* &:first-of-type {
    margin-top: 0;
    padding-top: 0;
  } */
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 12px;
  }
`;

const Checkbox = styled.span<{
  checked: boolean;
  color: string;
  wrong: boolean;
  right: boolean;
}>`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 3px;
  border: 1px solid
    ${(props) =>
      props.wrong
        ? colors.red
        : props.right
        ? props.color
        : props.checked
        ? colors.nearBlack
        : colors.darkGray};
  background-color: ${(props) =>
    props.wrong
      ? colors.white
      : props.right
      ? props.color
      : props.checked
      ? colors.nearBlack
      : colors.white};
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

type Props = {
  title?: string;
  input: {
    value: string[];
    name: string;
    onChange: (options: string[]) => void;
  };
  meta: {
    error: string;
    submitFailed?: boolean;
  };
  options: {
    title: string;
    value: string;
    correct?: boolean;
  }[];
  labelStyle?: Record<string, unknown>;
  inputStyle?: Record<string, unknown>;
  style?: Record<string, unknown>;
  quiz?: boolean;
};
const MultiSelectInput: React.FC<Props> = (props) => {
  const { input, meta, title, options, labelStyle, inputStyle, style, quiz } =
    props;

  const owner = useOwner();

  const sortedInputValue = input.value
    ? input.value.sort((a, b) => (b > a ? 1 : -1))
    : [];
  const sortedCorrectValue = options
    .filter((o) => !!o.correct)
    .map((o) => o.value)
    .sort((a, b) => (b > a ? 1 : -1));

  const correct =
    quiz && meta.submitFailed
      ? JSON.stringify(sortedInputValue) === JSON.stringify(sortedCorrectValue)
      : undefined;

  return (
    <Outer style={style}>
      {title && (
        <Top>
          <Label style={labelStyle}>{title}</Label>
          {correct === true && (
            <CheckIcon height={20} color={ownerColor(owner)} />
          )}
          {correct === false && <CrossIcon size={24} color={colors.red} />}
        </Top>
      )}

      <Wrapper data-cy={`input-${input.name}`}>
        {options.map((option) => {
          const checked = input.value && input.value.includes(option.value);
          const wrong = quiz
            ? meta.submitFailed &&
              input.value &&
              ((option.correct && !input.value.includes(option.value)) ||
                (!option.correct && input.value.includes(option.value)))
            : undefined;
          const right = quiz
            ? meta.submitFailed
              ? !!option.correct
              : false
            : undefined;

          return (
            <Option
              checked={checked}
              key={`multi-select-option-${option.value}`}
              style={inputStyle}
              wrong={wrong}
              right={right}
              color={ownerColor(owner)}
              data-cy={"multi-select-input-option"}
              onClick={() => {
                const newValue = input.value ? [...input.value] : [];
                if (input.value.includes(option.value)) {
                  const index = newValue.indexOf(option.value);
                  if (index !== -1) newValue.splice(index, 1);
                } else {
                  newValue.push(option.value);
                }
                input.onChange(Array.from(new Set(newValue)));
              }}
            >
              <Checkbox
                wrong={wrong}
                right={right}
                checked={checked}
                color={ownerColor(owner)}
              >
                {checked && (
                  <CheckIcon
                    color={wrong ? colors.red : colors.white}
                    style={{ marginBottom: 0 }}
                    height={12}
                  />
                )}
              </Checkbox>
              {option.title}
            </Option>
          );
        })}
        {meta?.error && <Error>{meta.error}</Error>}
      </Wrapper>
    </Outer>
  );
};

export default MultiSelectInput;
