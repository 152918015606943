import getProgram from "@/db/program/getProgram";
import { Program, Step } from "@/models/program";
import { sessionSelectors } from "@/redux/session";
import { find } from "lodash";
import router from "next/router";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import useAdmin from "./useAdmin";
import usePreview from "./usePreview";
import useProgram from "./useProgram";
import useSessionStepIndex from "./useSessionIndex";

const useEmbeddedProgram = () => {
  const program = useProgram();
  const admin = useAdmin();
  const stepIndex = useSessionStepIndex();
  const [parentStepIndex, embeddedStepIndex] = String(stepIndex).split(".");
  const programStep = program.steps[parentStepIndex] as Step;
  const programId =
    programStep?.type === "PROGRAM" ? programStep?.programId : null;
  const programQuery = useQuery(
    ["program", programId],
    getProgram(programId, admin),
    {
      refetchOnWindowFocus: false,
    }
  );
  return (programQuery?.data as Program) || null;
};
export default useEmbeddedProgram;
