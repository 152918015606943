import { colors } from "@/lib/styles";
import styled from "styled-components";
import { ChatStep } from "@/models/program";
import { ChatOpenAI } from "langchain/chat_models/openai";
import { StructuredOutputParser } from "langchain/output_parsers";
import { MEDIUM_MAX_WIDTH, MOBILE_WIDTH } from "@/lib/constants";
import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import toast from "react-hot-toast";
import Messages from "./Messages";
import Send from "./Send";
import { CallbackManager } from "langchain/callbacks";
import {
  AIMessagePromptTemplate,
  ChatPromptTemplate,
  HumanMessagePromptTemplate,
  MessagesPlaceholder,
  PromptTemplate,
  SystemMessagePromptTemplate,
} from "langchain/prompts";
import { ConversationChain, LLMChain } from "langchain/chains";
import { BufferMemory } from "langchain/memory";
import { OPENAI_API_KEY } from "@/env";
import useUserImage from "@/hooks/useUserImage";
import { z } from "zod";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/nextjs";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 1;
  height: 100%;
  max-height: 100%;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  border: 1px solid ${colors.lightGray};
  border-radius: 10px;
  height: 100%;
  width: 100%;
  flex: 1;
  max-width: ${MEDIUM_MAX_WIDTH}px;
  overflow-y: hidden;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    border: none;
  }
`;

type Props = {
  step: ChatStep;
  setDone: (done: boolean) => void;
};

export const TutorChat: React.FC<Props> = (props) => {
  const { step, setDone } = props;
  const router = useRouter();
  const [messages, setMessages] = useState<
    { text: string; from: "user" | "ai" | "tutor"; icon?: string }[]
  >([]);
  const tutorChainRef = useRef<ConversationChain>();
  const subjectChainRef = useRef<ConversationChain>();
  const userImage = useUserImage();

  // setup tutor and subject
  useEffect(() => {
    try {
      // setup tutor
      const tutorChat = new ChatOpenAI({
        modelName: "gpt-4",
        openAIApiKey: OPENAI_API_KEY,
        temperature: 0,
        streaming: true,
        callbackManager: CallbackManager.fromHandlers({
          async handleLLMStart() {
            setMessages((messages) => [
              ...messages,
              { text: "", icon: step.icon, from: "tutor" },
            ]);
          },
          async handleLLMNewToken(token: string) {
            setMessages((messages) => [
              ...messages.slice(0, messages.length - 1),
              {
                text: messages.slice(-1)[0].text + token,
                icon: messages.slice(-1)[0].icon,
                from: "tutor",
              },
            ]);
          },
          async handleLLMEnd() {},
        }),
      });

      const tutorInitMessages = [
        SystemMessagePromptTemplate.fromTemplate(step.prompt),
        AIMessagePromptTemplate.fromTemplate(step.firstMessage),
        new MessagesPlaceholder("history"),
        HumanMessagePromptTemplate.fromTemplate("{input}"),
      ];
      const tutorChatPrompt =
        ChatPromptTemplate.fromPromptMessages(tutorInitMessages);

      const tutorMemory = new BufferMemory({
        returnMessages: true,
        memoryKey: "history",
      });
      tutorMemory.chatHistory.addAIChatMessage(step.firstMessage);

      const tutorChain = new ConversationChain({
        memory: tutorMemory,
        prompt: tutorChatPrompt,
        llm: tutorChat,
      });
      tutorChainRef.current = tutorChain;

      // setup subject
      const subjectChat = new ChatOpenAI({
        modelName: "gpt-3.5-turbo",
        openAIApiKey: OPENAI_API_KEY,
        temperature: 0,
        streaming: true,
        callbackManager: CallbackManager.fromHandlers({
          async handleLLMStart() {
            setMessages((messages) => [
              ...messages,
              { text: "", icon: step.subjectIcon, from: "ai" },
            ]);
          },
          async handleLLMNewToken(token: string) {
            setMessages((messages) => [
              ...messages.slice(0, messages.length - 1),
              {
                text: messages.slice(-1)[0].text + token,
                icon: messages.slice(-1)[0].icon,
                from: "ai",
              },
            ]);
          },
          async handleLLMEnd() {},
        }),
      });

      const subjectInitMessages = [
        SystemMessagePromptTemplate.fromTemplate(step.subjectPrompt),
        AIMessagePromptTemplate.fromTemplate(step.subjectFirstMessage),
        new MessagesPlaceholder("history"),
        HumanMessagePromptTemplate.fromTemplate("{input}"),
      ];
      const subjectChatPrompt =
        ChatPromptTemplate.fromPromptMessages(subjectInitMessages);

      const subjectMemory = new BufferMemory({
        returnMessages: true,
        memoryKey: "history",
      });

      const subjectChain = new ConversationChain({
        memory: subjectMemory,
        prompt: subjectChatPrompt,
        llm: subjectChat,
      });
      subjectChainRef.current = subjectChain;

      tutorMemory.chatHistory.addUserMessage(
        `Subject: ${step.subjectFirstMessage}`
      );

      setMessages([
        { text: step.firstMessage, icon: step.icon, from: "tutor" },
        { text: step.subjectFirstMessage, icon: step.subjectIcon, from: "ai" },
      ]);
    } catch (e) {
      console.error(e);
    }
  }, []);

  // send message mutation
  const sendMessageMutation = useMutation(
    async (message: string) => {
      // add user message to local messages
      setMessages((messages) => [
        ...messages,
        { text: message, icon: userImage, from: "user" },
      ]);

      // call subject ai with message
      const subjectResponse = await subjectChainRef.current?.call({
        input: message,
      });

      async function sendTutorMessage() {
        // call tutor with subject's message and user's message
        await tutorChainRef.current?.call({
          input: `User: ${message}\nSubject: ${subjectResponse.response}`,
        });
      }

      await Promise.all([
        sendTutorMessage(),
        checkCompletionMutation.mutateAsync(),
      ]);

      await checkRestartMutation.mutateAsync();
    },
    {
      onError: (error) => {
        Sentry.captureException(error);
        toast.custom("Could not send message - try refreshing again");
      },
    }
  );

  // check completion mutation
  const checkCompletionMutation = useMutation(
    async () => {
      if (!step.complete) throw new Error("No completion prompt");

      const chat = new ChatOpenAI({
        temperature: 0,
        openAIApiKey: OPENAI_API_KEY,
        modelName: "gpt-4",
      });

      const parser = StructuredOutputParser.fromZodSchema(
        z.object({
          complete: z.boolean().describe("is the User finished? true/false"),
        })
      );

      const formatInstructions = parser.getFormatInstructions();

      const prompt = new PromptTemplate({
        template:
          "Here is the chat history between User and Subject\n\n----\n\n{chat}\n\n----\n\nYour task\n\n----\n\n{task}\n\n{format_instructions}",
        inputVariables: ["chat"],
        partialVariables: {
          format_instructions: formatInstructions,
          task: step.complete,
        },
      });

      const chain = new LLMChain({
        prompt: prompt,
        llm: chat,
      });

      const response = await chain.call({
        chat: messages
          .map(
            (message) =>
              `${
                message.from === "ai"
                  ? "Subject:"
                  : message.from === "tutor"
                  ? "Tutor:"
                  : "User:"
              } ${message.text}`
          )
          .join("\n"),
      });

      const parsedResponse = await parser.parse(response.text);
      return parsedResponse.complete;
    },
    {
      onSuccess: (complete) => {
        setDone(complete);
        if (complete) toast.success("You finished the step");
      },
      onError: (error) => {
        Sentry.captureException(error);
        toast.custom("Could not check completion - try refreshing the page");
      },
    }
  );

  // check restart mutation
  const checkRestartMutation = useMutation(
    async () => {
      if (!step.restart) throw new Error("No restart prompt");

      const chat = new ChatOpenAI({
        temperature: 0,
        openAIApiKey: OPENAI_API_KEY,
        modelName: "gpt-4",
        verbose: true,
      });

      const parser = StructuredOutputParser.fromZodSchema(
        z.object({
          restart: z
            .boolean()
            .describe("does the User need a forced restart? true/false"),
        })
      );

      const formatInstructions = parser.getFormatInstructions();

      const prompt = new PromptTemplate({
        template:
          "Here is the chat history between User and Subject\n\n----\n\n{chat}\n\n----\n\nYour task\n\n----\n\n{task}\n\n{format_instructions}",
        inputVariables: ["chat"],
        partialVariables: {
          format_instructions: formatInstructions,
          task: step.restart,
        },
      });

      const chain = new LLMChain({
        prompt: prompt,
        llm: chat,
      });

      const response = await chain.call({
        chat: messages
          .map(
            (message) =>
              `${
                message.from === "ai"
                  ? "Subject:"
                  : message.from === "tutor"
                  ? "Tutor:"
                  : "User:"
              } ${message.text}`
          )
          .join("\n"),
      });

      const parsedResponse = await parser.parse(response.text);

      return parsedResponse.restart;
    },
    {
      onSuccess: (restart) => {
        if (restart) {
          toast.error("You made a mistake and will need to try again");
          setTimeout(() => {
            router.reload();
          }, 1000);
        }
      },
      onError: (error) => {
        Sentry.captureException(error);
        toast.custom("Could not evaluate session - try refreshing the page");
      },
    }
  );

  return (
    <>
      <Wrapper>
        <Content data-cy={"step-chat-content"}>
          <Messages messages={messages} />
          <Send sendMessageMutation={sendMessageMutation} />
        </Content>
      </Wrapper>
    </>
  );
};

export default React.memo(TutorChat);
