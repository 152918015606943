import React from "react";
import { colors } from "@/lib/styles";

type Props = {
  size?: number;
  style?: Record<string, unknown>;
  color?: string;
  fill?: string;
};
const StarIcon: React.FC<Props> = (props) => {
  const size = props.size ? String(props.size) : "24";

  return (
    <span style={{ transform: "translateY(2px)", ...props.style }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={props.fill}
        stroke={props.color}
        strokeWidth="2"
        vectorEffect="non-scaling-stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
      </svg>
    </span>
  );
};
StarIcon.defaultProps = {
  color: colors.darkGray,
  size: 16,
  fill: "none",
};

export default StarIcon;
