import StyledMarkdown from "@/components/universal/StyledMarkdown";
import useOwnerColor from "@/hooks/useOwnerColor";
import { colors } from "@/lib/styles";
import { useEffect, useRef } from "react";
import styled from "styled-components";

export default function Messages({
  messages,
}: {
  messages: {
    text: string;
    icon?: string;
    from: "user" | "ai" | "tutor";
  }[];
}) {
  const ownerColor = useOwnerColor();
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages.length]);

  return (
    <Wrapper>
      {messages.map((message, index) => (
        <MessageWrapper key={index} type={message.from} color={ownerColor}>
          {message.icon && (
            <MessageIcon type={message.from} src={message.icon} alt="icon" />
          )}
          <StyledMarkdown text={message.text} />
        </MessageWrapper>
      ))}
      <div ref={messagesEndRef} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  height: calc(100vh - 220px);
  overflow-y: scroll;
  padding-bottom: 100px;
`;

const MessageWrapper = styled.div<{
  type: "user" | "ai" | "tutor";
  color: string;
}>`
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-top: ${(props) => (props.type === "tutor" ? "50px" : "20px")};
  margin-left: ${(props) =>
    props.type === "user" || props.type === "tutor" ? "auto" : "50px"};
  margin-right: ${(props) => (props.type === "user" ? "50px" : "auto")};
  border-radius: 10px;
  color: ${(props) =>
    props.type === "user" ? colors.white : colors.nearBlack};
  background-color: ${(props) =>
    props.type === "user"
      ? props.color
      : props.type === "tutor"
      ? colors.nearWhitePrimary
      : colors.nearWhite};
  max-width: ${(props) => (props.type === "tutor" ? "70%" : "80%")};
  position: relative;
`;

const MessageIcon = styled.img<{ type: "user" | "ai" | "tutor" }>`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  bottom: ${(props) => (props.type === "tutor" ? "auto" : "-35px")};
  top: ${(props) => (props.type === "tutor" ? "-35px" : "auto")};
  left: ${(props) =>
    props.type === "user"
      ? "auto"
      : props.type === "tutor"
      ? "calc(50% - 25px)"
      : "-35px"};
  right: ${(props) =>
    props.type === "user"
      ? "-35px"
      : props.type === "tutor"
      ? "calc(50% - 25px)"
      : "auto"};
  border: 2px solid ${colors.white};
`;
