import React from "react";
import styled from "styled-components";
import { colors } from "@/lib/styles";
import { MOBILE_WIDTH } from "@/lib/constants";
import CheckIcon from "@/components/icons/CheckIcon";
import { find } from "lodash";
import CrossIcon from "@/components/icons/CrossIcon";
import useOwner from "@/hooks/useOwner";
import useOwnerColor from "@/hooks/useOwnerColor";

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-size: 21px;
  font-weight: 500;
  color: ${colors.nearBlack};
  margin-right: 10px;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 12px;
  }
`;

const Error = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${colors.darkGray};
  margin-top: 5px;
  width: 100%;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 12px;
  }
`;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Option = styled.div<{
  checked: boolean;
  empty: boolean;
  color: string;
  wrong: boolean;
  right: boolean;
}>`
  user-select: none;
  font-size: 21px;
  font-weight: 500;
  color: ${(props) =>
    props.wrong
      ? colors.red
      : props.right
      ? props.color
      : props.checked
      ? colors.nearBlack
      : props.empty
      ? colors.nearBlack
      : colors.gray};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
  display: flex;
  flex-direction: center;
  align-items: center;
  text-align: left;
  width: 100%;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 12px;
  }
`;

const Explanation = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${colors.darkGray};
`;

const Checkbox = styled.span<{
  checked: boolean;
  empty: boolean;
  color: string;
  wrong: boolean;
  right: boolean;
}>`
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid
    ${(props) =>
      props.wrong
        ? colors.red
        : props.right
        ? props.color
        : props.checked
        ? colors.nearBlack
        : props.empty
        ? colors.darkGray
        : colors.gray};
  background-color: ${(props) =>
    props.wrong
      ? colors.white
      : props.right
      ? colors.white
      : props.checked
      ? colors.nearBlack
      : colors.white};
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

type Props = {
  title?: string;
  input: {
    value: string;
    name: string;
    onChange: (option: string) => void;
  };
  meta: {
    error: string;
    submitFailed?: boolean;
  };
  options: {
    title: string;
    value: string;
    correct?: boolean;
    explanation?: string;
  }[];
  labelStyle?: Record<string, unknown>;
  inputStyle?: Record<string, unknown>;
  style?: Record<string, unknown>;
  quiz?: boolean;
};
const SelectInput: React.FC<Props> = (props) => {
  const { style, input, meta, title, options, labelStyle, inputStyle, quiz } =
    props;

  const owner = useOwner();
  const ownerColor = useOwnerColor();

  const selected = find(options, { value: input.value });

  return (
    <Outer style={style}>
      {title && (
        <Top>
          <Label style={labelStyle}>{title}</Label>
          {selected?.correct === true && (
            <CheckIcon height={20} color={ownerColor} />
          )}
          {selected?.correct === false && (
            <CrossIcon size={24} color={colors.red} />
          )}
        </Top>
      )}
      <Wrapper data-cy={`input-${input.name}`}>
        {options.map((option, i) => {
          const checked = input.value == option.value;
          const wrong =
            quiz && input.value
              ? !option.correct && input.value === option.value
                ? true
                : false
              : undefined;
          const right =
            quiz && input.value
              ? !!option.correct && input.value === option.value
                ? true
                : false
              : undefined;

          return (
            <Option
              checked={checked}
              key={`select-option-${input.name}-${i}`}
              empty={!input.value}
              style={inputStyle}
              wrong={wrong}
              right={right}
              color={ownerColor}
              data-cy={"select-input-option"}
              onClick={() => {
                input.onChange(
                  input.value === option.value ? null : option.value
                );
              }}
            >
              <Checkbox
                wrong={wrong}
                right={right}
                checked={checked}
                color={ownerColor}
                empty={!input.value}
              >
                {checked && (
                  <CheckIcon
                    color={
                      wrong ? colors.red : right ? ownerColor : colors.white
                    }
                    style={{ marginBottom: 1 }}
                    height={12}
                  />
                )}
              </Checkbox>
              {option.title}
            </Option>
          );
        })}
        {meta?.error && <Error>{meta.error}</Error>}
        {selected?.explanation && (
          <Explanation
            style={{ color: selected?.correct ? ownerColor : colors.red }}
          >
            {selected?.explanation}
          </Explanation>
        )}
      </Wrapper>
    </Outer>
  );
};

export default SelectInput;
