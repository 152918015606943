import React from "react";
import styled from "styled-components";
import { reduxForm, InjectedFormProps, Field, FormSection } from "redux-form";
import { colors } from "@/lib/styles";
import { SurveyStep } from "@/models/program";
import Input from "@/components/universal/LegacyInput/Input";
import MultiSelectInput from "@/components/universal/LegacyInput/MultiSelectInput";
import { MEDIUM_MAX_WIDTH, MOBILE_WIDTH } from "@/lib/constants";
import SelectInput from "@/components/universal/LegacyInput/SelectInput";
import RangeInput from "@/components/universal/LegacyInput/RangeInput";
import { useRouter } from "next/router";
import useWindowSize from "@/hooks/useWindowSize";
import CheckIcon from "@/components/icons/CheckIcon";
import { SurveyResponse } from "@/models/survey";
import { calculateScore } from "@/lib/score";
import { ownerColor } from "@/lib/owner";
import useOwner from "@/hooks/useOwner";

const Error = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${colors.red};
`;

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 10px 20px;
  width: 100%;
  margin: auto;
  max-width: ${MEDIUM_MAX_WIDTH}px;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    padding: 20px 15px 80px;
    border: none;
  }
`;

const Button = styled.button<{ color: string }>`
  padding: 10px 15px;
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  border: none;
  border-radius: 10px;
  background-color: ${(props) => props.color};
  color: ${colors.white};
  font-weight: 600;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  box-shadow: rgba(255, 255, 255, 0.5) 0 1px 5px 0;
  outline: none;
  text-decoration: none;
  width: auto;
  align-self: center;
  margin: 20px 0;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    margin-top: 10px;
  }
`;

const Scored = styled.span`
  display: flex;
  flex-direction: row;
  font-size: 21px;
  font-weight: 600;
  color: ${colors.nearBlack};
  align-self: center;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 16px;
  }
`;

const Completed = styled.span<{ color: string }>`
  display: flex;
  flex-direction: row;
  font-size: 21px;
  font-weight: 600;
  width: 100%;
  justify-content: center;
  padding: 20px 0;
  color: ${(props) => props.color};
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 16px;
  }
`;

type FormData = Record<string, unknown>;

type FormProps = {
  step: SurveyStep;
  survey?: SurveyResponse;
};

const SurveyFormComponent: React.FC<
  FormProps & InjectedFormProps<FormData, FormProps>
> = (props) => {
  const {
    step,
    handleSubmit,
    error,
    submitting,
    submitSucceeded,
    pristine,
    survey,
  } = props;

  const router = useRouter();
  const sessionId = router.query.session as string;

  const owner = useOwner();

  const { width } = useWindowSize();

  const score = calculateScore(step, survey);
  if (score) console.log(score);

  return (
    <>
      <Wrapper
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <FormSection name="questions">
          {step.questions?.map((question, i) => {
            switch (question.type) {
              case "SHORT_ANSWER":
                return (
                  <Field
                    key={`survey-form-field-${question.questionId}`}
                    component={Input}
                    name={question.questionId}
                    title={question.prompt}
                    type="text"
                  />
                );
              case "RANGE":
                return (
                  <Field
                    key={`survey-form-field-${question.questionId}`}
                    component={RangeInput}
                    name={question.questionId}
                    title={question.prompt}
                    type="text"
                    options={question.options.map((option, i) => ({
                      title: option.value,
                      value: i,
                    }))}
                  />
                );
              case "MULTIPLE_CHOICE":
                if (question.selectMultiple) {
                  return (
                    <Field
                      key={`survey-form-field-${question.questionId}`}
                      component={MultiSelectInput}
                      name={question.questionId}
                      title={question.prompt}
                      options={question.options.map((option) => ({
                        title: option.value,
                        value: option.optionId,
                        correct: option.correct,
                        explanation: option.explanation,
                      }))}
                      type="text"
                      quiz={step.surveyType === "QUIZ"}
                    />
                  );
                } else {
                  return (
                    <Field
                      key={`survey-form-field-${question.questionId}`}
                      component={SelectInput}
                      name={question.questionId}
                      title={question.prompt}
                      options={question.options.map((option) => ({
                        title: option.value,
                        value: option.optionId,
                        correct: option.correct,
                        explanation: option.explanation,
                      }))}
                      type="text"
                      quiz={step.surveyType === "QUIZ"}
                    />
                  );
                }
                break;
            }
          })}
        </FormSection>

        {sessionId && !survey?.completedTime && (
          <Button
            disabled={submitting}
            color={ownerColor(owner)}
            style={{ zIndex: 100 }}
            data-cy={"submit-survey"}
          >
            {submitting ? "Loading..." : "Submit"}
          </Button>
        )}
        {score && step.surveyType === "QUIZ" && !!survey?.completedTime && (
          <Scored>
            {`${Math.round(score.score * 100)}% (${score.correct}/${
              score.total
            })`}
          </Scored>
        )}
        {step.surveyType !== "QUIZ" && !!survey?.completedTime && (
          <Completed color={ownerColor(owner)}>
            <CheckIcon
              style={{ marginRight: 5 }}
              height={16}
              color={ownerColor(owner)}
            />{" "}
            Completed
          </Completed>
        )}

        {error && <Error>{error}</Error>}
      </Wrapper>
    </>
  );
};

const SurveyForm = (
  programId: string,
  surveyId: string,
  sessionId: string,
  embeddedStepId?: string
) =>
  reduxForm<FormData, FormProps>({
    form: `program-${programId}-survey-${surveyId}-session-${sessionId}-${
      embeddedStepId || "normal"
    }`,
    destroyOnUnmount: false,
  })(SurveyFormComponent);
export default SurveyForm;
