import { firestore } from "@/firebase";
import moment from "moment";
import * as Sentry from "@sentry/nextjs";
import { Session } from "@/models/session";
import getProgram from "../program/getProgram";
import getSession from "./getSession";
import completeSession from "./completeSession";
import router from "next/router";
import { ownerPath } from "@/lib/path";
import startSessionStep from "./startSessionStep";
import { Program } from "@/models/program";
import { em } from "polished";
import { Owner } from "@/models/owner";
import startSession from "./startSession";
import { Group } from "@/models/group";

const nextSessionStep = async (data: {
  owner: Owner;
  program: Program;
  embeddedProgram?: Program;
  sessionId?: string;
  admin?: boolean;
}): Promise<void> => {
  const { owner, program, embeddedProgram, sessionId, admin } = data;
  try {
    // preview mode
    if (!sessionId) {
      const [activeIndex, embeddedActiveIndex] = String(router.query.i)
        .split(".")
        .map(Number);
      // if currently in embedded program
      if (program?.steps?.[activeIndex]?.type === "PROGRAM") {
        // if at the end of the embedded program
        if ((embeddedActiveIndex || 0) >= embeddedProgram.steps.length - 1) {
          // go to next step in parent program
          router.replace(
            ownerPath(owner, `/program/${program.id}/preview`, {
              i: `${activeIndex + 1}`,
            })
          );
          console.log(activeIndex);
        }
        // if not at the end of the embedded program
        else {
          // go to next step in embedded program
          router.replace(
            ownerPath(owner, `/program/${program.id}/preview`, {
              i: `${activeIndex}.${(embeddedActiveIndex || 0) + 1}`,
            })
          );
          console.log(activeIndex, embeddedActiveIndex);
        }
      }
      // if not currently in embedded program
      else {
        // if at the end of the parent program
        if ((activeIndex || 0) >= program.steps.length - 1) {
          // do nothing
        }
        // if not at the end of the parent program
        else {
          // go to next step in parent program
          router.replace(
            ownerPath(owner, `/program/${program.id}/preview`, {
              i: activeIndex + 1,
            })
          );
          console.log(activeIndex);
        }
      }
      return;
    }

    // session mode
    const sessionDoc = await firestore
      .collection("sessions")
      .doc(sessionId)
      .get();
    if (sessionDoc.exists) {
      const session = sessionDoc.data() as Session;
      const [activeIndex, embeddedActiveIndex] = String(session.activeIndex)
        .split(".")
        .map(Number);

      const programStep = program?.steps[activeIndex];

      // if currently on embedded program
      if (programStep.type === "PROGRAM") {
        // if embedded program -> next step in embedded session
        const embeddedSession = await startSession({
          userId: session.userId,
          orgId: session.orgId,
          programId: programStep.programId,
          embeddedSessionId: session.id,
          embeddedStepId: programStep.stepId,
        });
        if (!embeddedSession)
          throw new Error("Could not find embedded session");

        if (!embeddedProgram)
          throw new Error("Could not find embedded program");

        // complete prior embedded program step
        const newEmbeddedSteps = embeddedSession.steps;
        newEmbeddedSteps[embeddedActiveIndex || 0] = {
          ...newEmbeddedSteps[embeddedActiveIndex || 0],
          completedTime: moment().unix(),
        };

        await firestore.collection("sessions").doc(embeddedSession.id).update({
          steps: newEmbeddedSteps,
        });

        // only continue if embedded session is not finished -> otherwise, continue with parent session
        if ((embeddedActiveIndex || 0) < embeddedProgram?.steps?.length - 1) {
          await firestore
            .collection("sessions")
            .doc(embeddedSession.id)
            .update({
              activeIndex: (embeddedActiveIndex || 0) + 1,
            });

          const newParentIndex = Number(
            `${activeIndex}.${(embeddedActiveIndex || 0) + 1}`
          );

          await sessionDoc.ref.update({
            activeIndex: newParentIndex,
          });

          // start next embedded program step
          await startSessionStep({
            stepIndex: newParentIndex,
            sessionId: session?.id,
          });

          return;
        } else {
          // embedded session is finished -> mark as completed
          await completeSession({ sessionId: embeddedSession.id });
        }
      }

      // if normal step -> continue with next step
      console.log("normal step");
      const newSteps = session.steps;
      newSteps[activeIndex] = {
        ...newSteps[activeIndex],
        completedTime: moment().unix(),
      };

      sessionDoc.ref.update({
        steps: newSteps,
      });

      // if last step -> mark session as completed
      if (activeIndex === program?.steps?.length - 1) {
        await completeSession({ sessionId });
        await router.push(
          ownerPath(owner, `program/${program.id}/complete`, {
            session: sessionId,
          })
        );
      } else {
        // if not last step -> continue with next step
        await startSessionStep({
          stepIndex: activeIndex + 1,
          sessionId: session?.id,
        });

        sessionDoc.ref.update({
          activeIndex: activeIndex + 1,
        });
      }
    } else throw new Error("Could not retrieve session");
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);
  }
};

export default nextSessionStep;
