import { firestore } from "@/firebase";
import moment from "moment";
import * as Sentry from "@sentry/nextjs";
import { Session } from "@/models/session";
import getProgram from "../program/getProgram";
import startSession from "./startSession";
import getGroup from "../group/getGroup";
import getSession from "./getSession";
import startSurvey from "../survey/startSurvey";

// starts a session step
const startSessionStep = async (data: {
  sessionId: string;
  stepIndex: number;
  admin?: boolean;
}): Promise<void> => {
  const { sessionId, stepIndex, admin } = data;
  try {
    const sessionDoc = await firestore
      .collection("sessions")
      .doc(sessionId)
      .get();
    if (sessionDoc.exists) {
      const session = sessionDoc.data() as Session;

      const [parentActiveIndex, embeddedActiveIndex] = String(stepIndex)
        .split(".")
        .map(Number);

      const program = await getProgram(session.programId, admin)();
      const programStep = program?.steps[parentActiveIndex];

      // if embedded program
      if (programStep.type === "PROGRAM") {
        const embeddedSession = await startSession({
          programId: programStep?.programId,
          orgId: session.orgId,
          userId: session.userId,
          embeddedSessionId: session.id,
          embeddedStepId: programStep?.stepId,
        });
        const newEmbeddedSteps = embeddedSession.steps;
        newEmbeddedSteps[embeddedActiveIndex || 0] = {
          startedTime: moment().unix(),
        };

        const embeddedProgram = await getProgram(programStep?.programId)();
        const embeddedProgramStep =
          embeddedProgram?.steps?.[embeddedActiveIndex || 0];

        if (embeddedProgram && embeddedProgramStep.type === "SURVEY")
          await startSurvey({
            orgId: program.orgId,
            programId: embeddedProgram.id,
            userId: embeddedSession.userId,
            surveyId: embeddedProgramStep.surveyId,
            sessionId: embeddedSession.id,
            embeddedSessionId: session.id,
            embeddedStepId: programStep?.stepId,
          });

        await firestore.collection("sessions").doc(embeddedSession.id).update({
          steps: newEmbeddedSteps,
        });
      } else {
        // if normal step
        if (programStep.type === "SURVEY") {
          await startSurvey({
            orgId: program.orgId,
            programId: program.id,
            userId: session.userId,
            surveyId: programStep.surveyId,
            sessionId: session.id,
          });
        }
        const newSteps = session.steps;
        newSteps[parentActiveIndex] = {
          ...session.steps[parentActiveIndex],
          startedTime: moment().unix(),
        };

        sessionDoc.ref.update({
          steps: newSteps,
        });
      }
    } else throw new Error("Could not retrieve session");
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);
  }
};

export default startSessionStep;
