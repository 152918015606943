import styled from "styled-components";
import { ImageStep } from "@/models/program";
import { useRouter } from "next/router";
import Image from "next/image";
import { useEffect } from "react";
import { colors } from "@/lib/styles";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid ${colors.lightGray};
  flex: 1;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

type Props = {
  step: ImageStep;
  setDone: (done: boolean) => void;
};

export const ImageComponent: React.FC<Props> = (props) => {
  const { step, setDone } = props;

  const router = useRouter();

  useEffect(() => {
    setTimeout(() => {
      setDone(true);
    }, 1000);
  }, []);

  return (
    <>
      <Wrapper>
        {step.image && (
          <ImageWrapper>
            <Image layout="fill" objectFit="contain" src={step.image} />
          </ImageWrapper>
        )}
      </Wrapper>
    </>
  );
};

export default ImageComponent;
