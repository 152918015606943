import { colors } from "@/lib/styles";
import { RootState } from "@/redux";

import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import styled from "styled-components";
import { TextStep } from "@/models/program";
import Editor from "rich-markdown-editor";
import sh from "shorthash";
import { useEffect, useState } from "react";
import { MEDIUM_MAX_WIDTH, MOBILE_WIDTH } from "@/lib/constants";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 1;
  height: 100%;
  max-height: 100%;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  border: 1px solid ${colors.lightGray};
  border-radius: 10px;
  height: 100%;
  width: 100%;
  flex: 1;
  max-width: ${MEDIUM_MAX_WIDTH}px;
  overflow-y: scroll;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    border: none;
  }
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: 600;
  padding: 20px;
`;

type Props = {
  step: TextStep;
  setDone: (done: boolean) => void;
} & ConnectedProps<typeof connector>;

export const Text: React.FC<Props> = (props) => {
  const { step, setDone } = props;

  // rich-markdown-editor workaround for SSR
  const [render, setRender] = useState(false);
  useEffect(() => {
    setRender(true);
    setTimeout(() => {
      setDone(true);
    }, 1000);
  }, []);

  return (
    <>
      <Wrapper>
        <Content data-cy={"step-text-content"}>
          {step.text && (
            <Editor
              id={`editor-${sh.unique(step.text)}`}
              key={`editor-${sh.unique(step.text)}`}
              value={render ? step.text : undefined}
              style={{
                padding: "20px",
                width: "100%",
              }}
              onChange={undefined}
              readOnly={true}
            />
          )}
        </Content>
      </Wrapper>
    </>
  );
};

const mapState = (state: RootState) => ({});
const mapDispatch = (dispatch: Dispatch) => bindActionCreators({}, dispatch);
const connector = connect(mapState, mapDispatch);

export default connector(Text);
