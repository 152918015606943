import { firestore } from "@/firebase";
import * as Sentry from "@sentry/nextjs";
import { Session } from "@/models/session";
import getProgram from "../program/getProgram";
import { Program } from "@/models/program";
import { Owner } from "@/models/owner";
import router from "next/router";
import { ownerPath } from "@/lib/path";
import startSession from "./startSession";
import { Group } from "@/models/group";

// starts a session step
const previousSessionStep = async (data: {
  program: Program;
  embeddedProgram?: Program;
  owner: Owner;
  sessionId?: string;
  admin?: boolean;
}): Promise<void> => {
  const { owner, program, embeddedProgram, sessionId, admin } = data;

  // preview mode
  if (!sessionId) {
    const [activeIndex, embeddedActiveIndex] = String(router.query.i)
      .split(".")
      .map(Number);
    // if currently in embedded program
    if (program?.steps?.[activeIndex]?.type === "PROGRAM") {
      // if at the beginning of the embedded program
      if ((embeddedActiveIndex || 0) === 0 && activeIndex > 0) {
        // go to previous step in parent program
        router.replace(
          ownerPath(owner, `/program/${program.id}/preview`, {
            i: `${activeIndex - 1}`,
          })
        );
      }
      // if not at the beginning of the embedded program
      else {
        // go to previous step in embedded program
        router.replace(
          ownerPath(owner, `/program/${program.id}/preview`, {
            i: `${activeIndex}.${(embeddedActiveIndex || 0) - 1}`,
          })
        );
      }
    }
    // if not currently in embedded program
    else {
      // if at the beginning of the parent program
      if ((activeIndex || 0) === 0) {
        // do nothing
      }
      // if not at the beginning of the parent program
      else {
        // go to previous in parent program
        router.replace(
          ownerPath(owner, `/program/${program.id}/preview`, {
            i: `${activeIndex - 1}`,
          })
        );
      }
    }
  }

  try {
    const sessionDoc = sessionId
      ? await firestore.collection("sessions").doc(sessionId).get()
      : null;
    if (sessionDoc?.exists) {
      // live session
      const session = sessionDoc.data() as Session;
      const [activeIndex, embeddedActiveIndex] = String(session.activeIndex)
        .split(".")
        .map(Number);

      const program = await getProgram(session.programId, admin)();
      const programStep = program?.steps[activeIndex];

      if (programStep.type === "PROGRAM") {
        // if embedded program -> try previous step in embedded session
        const embeddedSession = await startSession({
          userId: session.userId,
          programId: programStep.programId,
          orgId: session.orgId,
          embeddedSessionId: session.id,
          embeddedStepId: programStep.stepId,
        });
        if (!embeddedSession)
          throw new Error("Could not find embedded session");

        const embeddedProgram = await getProgram(
          programStep.programId,
          admin
        )();
        if (!embeddedProgram)
          throw new Error("Could not find embedded program");

        // only go back if embedded session is not at first step -> otherwise, go back in parent session
        if ((embeddedActiveIndex || 0) !== 0) {
          await firestore
            .collection("sessions")
            .doc(embeddedSession.id)
            .update({
              activeIndex: (embeddedActiveIndex || 0) - 1,
            });

          await firestore
            .collection("sessions")
            .doc(embeddedSession.id)
            .update({
              activeIndex: (embeddedActiveIndex || 0) - 1,
            });

          const newParentIndex = Number(
            `${activeIndex}.${(embeddedActiveIndex || 0) - 1}`
          );

          await sessionDoc.ref.update({
            activeIndex: newParentIndex,
          });

          return;
        }
      }

      if (activeIndex === 0) return;

      // check if previous step is a program and go to correct step in embedded session
      const previousProgramStep = program?.steps[activeIndex - 1];
      if (previousProgramStep.type === "PROGRAM") {
        const embeddedSession = await startSession({
          userId: session.userId,
          programId: previousProgramStep.programId,
          orgId: session.orgId,
          embeddedSessionId: session.id,
          embeddedStepId: previousProgramStep.stepId,
        });
        if (!embeddedSession)
          throw new Error("Could not find embedded session");
        sessionDoc.ref.update({
          activeIndex: Number(
            `${activeIndex - 1}.${embeddedSession.activeIndex}`
          ),
        });
      } else {
        sessionDoc.ref.update({
          activeIndex: activeIndex - 1,
        });
      }
    } else throw new Error("Could not retrieve session");
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);
  }
};

export default previousSessionStep;
