import { colors } from "@/lib/styles";
import styled from "styled-components";
import StepLine from "./StepLine";
import { MOBILE_WIDTH } from "@/lib/constants";
import useProgram from "@/hooks/useProgram";
import useSessionStepIndex from "@/hooks/useSessionIndex";
import { useCallback } from "react";
import { useRouter } from "next/router";
import useOwner from "@/hooks/useOwner";
import { ownerPath } from "@/lib/path";
import usePreview from "@/hooks/usePreview";

const Wrapper = styled.div`
  width: 300px;
  border-right: 1px solid ${colors.lightGray};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    display: none;
  }
`;

const StepsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  overflow-y: auto;
`;

type Props = {};

export const Sidebar: React.FC<Props> = (props) => {
  const preview = usePreview();
  const owner = useOwner();
  const program = useProgram();
  const router = useRouter();
  const sessionStepIndex = useSessionStepIndex();

  const jumpToStep = useCallback(
    (i: number) => {
      router.replace(ownerPath(owner, `program/${program?.id}/preview`, { i }));
    },
    [program, router]
  );

  return (
    <>
      <Wrapper>
        {!!program && (
          <StepsWrapper>
            {program.steps.map((step, index) => (
              <StepLine
                key={`sortable-step-${index}`}
                step={step}
                stepIndex={index}
                active={sessionStepIndex === index}
                onClick={preview ? () => jumpToStep(index) : undefined}
                completed={preview ? false : index < sessionStepIndex}
              />
            ))}
          </StepsWrapper>
        )}
      </Wrapper>
    </>
  );
};

export default Sidebar;
