import styled from "styled-components";
import React, { useEffect, useState } from "react";
import Text from "@/components/Steps/Text/Session";
import Chat from "@/components/Steps/Chat/Session";
import Video from "@/components/Steps/Video/Session";
import Survey from "@/components/Steps/Survey/Session";
import Section from "@/components/Steps/Section/Session";
import { useRouter } from "next/router";
import { MOBILE_WIDTH } from "@/lib/constants";
import Pdf from "@/components/Steps/Pdf/Session";
import Image from "@/components/Steps/Image/Session";
import EmbeddedProgram from "@/components/Steps/EmbeddedProgram/Session";
import Actions from "@/components/Session/Actions";
import Header from "@/components/Steps/Header/Session";
import useSessionStepIndex from "@/hooks/useSessionIndex";
import useProgram from "@/hooks/useProgram";
import { ownerPath } from "@/lib/path";
import usePreview from "@/hooks/usePreview";
import useOwner from "@/hooks/useOwner";
import { Program, Step as ProgramStep } from "@/models/program";

const Wrapper = styled.div`
  flex: 1;
  padding: 20px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    padding: 0 0 0 0;
  }
`;

const StepComponent: React.FC<{
  program?: Program;
  programStep: ProgramStep;
  setDone: (done: boolean) => void;
  done: boolean;
  Component: React.FC<{
    step: ProgramStep;
    setDone: (done: boolean) => void;
    program?: Program;
  }>;
  stepIndex: number;
}> = (props) => {
  const { programStep, setDone, done, Component, stepIndex, program } = props;

  return (
    <Wrapper key={`step-wrapper`}>
      <Header step={programStep} />
      <Component
        key={`program-step-${stepIndex}`}
        step={programStep}
        setDone={setDone}
        program={program}
      />
      <Actions done={done} />
    </Wrapper>
  );
};

export const Step: React.FC<{}> = (props) => {
  const {} = props;

  const router = useRouter();
  const [done, setDone] = useState(false);
  const program = useProgram();
  const owner = useOwner();
  const preview = usePreview();
  const stepIndex = useSessionStepIndex();

  // reset done on step change
  useEffect(() => {
    if (done === true) setDone(false);
  }, [stepIndex]);

  useEffect(() => {
    if (preview)
      router.replace(
        ownerPath(owner, `program/${program?.id}/preview`, {
          i: stepIndex,
        }),
        undefined,
        { shallow: true }
      );
  }, [preview, stepIndex]);

  const programStep =
    program && program.steps && program.steps[stepIndex]
      ? program.steps[stepIndex]
      : null;

  if (!programStep) return null;

  const stepTestLabel = `step-${programStep.stepId}`;

  switch (programStep.type) {
    case "PROGRAM":
      return (
        <StepComponent
          key={`step-component-${stepIndex}`}
          stepIndex={stepIndex}
          programStep={programStep}
          setDone={setDone}
          done={done}
          Component={EmbeddedProgram}
        />
      );
    case "CHAT":
      return (
        <StepComponent
          key={`step-component-${stepIndex}`}
          stepIndex={stepIndex}
          programStep={programStep}
          setDone={setDone}
          done={done}
          Component={Chat}
        />
      );
    case "TEXT":
      return (
        <StepComponent
          key={`step-component-${stepIndex}`}
          stepIndex={stepIndex}
          programStep={programStep}
          setDone={setDone}
          done={done}
          Component={Text}
        />
      );
    case "IMAGE":
      return (
        <StepComponent
          key={`step-component-${stepIndex}`}
          stepIndex={stepIndex}
          programStep={programStep}
          setDone={setDone}
          done={done}
          Component={Image}
        />
      );
    case "VIDEO":
      return (
        <StepComponent
          key={`step-component-${stepIndex}`}
          stepIndex={stepIndex}
          programStep={programStep}
          setDone={setDone}
          done={done}
          Component={Video}
        />
      );
    case "PDF":
      return (
        <StepComponent
          key={`step-component-${stepIndex}`}
          stepIndex={stepIndex}
          programStep={programStep}
          setDone={setDone}
          done={done}
          Component={Pdf}
        />
      );
    case "SURVEY":
      return (
        <StepComponent
          key={`step-component-${stepIndex}`}
          stepIndex={stepIndex}
          program={program}
          programStep={programStep}
          setDone={setDone}
          done={done}
          Component={Survey}
        />
      );
    case "SECTION":
      return (
        <StepComponent
          key={`step-component-${stepIndex}`}
          stepIndex={stepIndex}
          programStep={programStep}
          setDone={setDone}
          done={done}
          Component={Section}
        />
      );
  }
};

export default Step;
