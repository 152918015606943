import ArrowIcon from "@/components/icons/ArrowIcon";
import TextAreaInput from "@/components/universal/Input/TextAreaInput";
import useOwnerColor from "@/hooks/useOwnerColor";
import { colors } from "@/lib/styles";
import { ChangeEvent, useState } from "react";
import { UseMutationResult } from "react-query";
import styled from "styled-components";
import Loading from "react-loading";

export default function Send({
  sendMessageMutation,
}: {
  sendMessageMutation: UseMutationResult<void, unknown, string, unknown>;
}) {
  const [input, setInput] = useState("");
  const ownerColor = useOwnerColor();
  return (
    <Wrapper
      onSubmit={(e: React.KeyboardEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (e.shiftKey) {
          setInput(input + "\n");
        } else {
          sendMessageMutation.mutate(input);
          setInput("");
        }
      }}
    >
      <TextAreaInput
        name="input"
        inputStyle={{
          width: "100%",
          paddingRight: "40px",
          maxWidth: "100%",
          minWidth: "100%",
          minHeight: "60px",
          boxShadow: "0px 20px 20px 20px rgba(255, 255, 255, 0.8)",
        }}
        type="text"
        autoFocus
        value={input}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setInput(e.target.value)
        }
        onKeyDown={(e) => {
          if (
            e.key === "Enter" &&
            !e.shiftKey &&
            !sendMessageMutation.isLoading
          ) {
            e.preventDefault();
            sendMessageMutation.mutate(input);
            setInput("");
          }
        }}
      />
      <Button
        type="submit"
        color={ownerColor}
        disabled={sendMessageMutation.isLoading}
      >
        {sendMessageMutation.isLoading && (
          // @ts-ignore
          <Loading type="bubbles" height={25} width={25} color={colors.white} />
        )}
        {!sendMessageMutation.isLoading && (
          <ArrowIcon
            size={25}
            color={colors.white}
            style={{
              transform: "translateY(2px)",
            }}
          />
        )}
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.form`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 5px;
  left: 20px;
  right: 20px;
`;

const Button = styled.button<{ color: string }>`
  position: absolute;
  right: 10px;
  bottom: 20px;
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
