import { useEffect } from "react";
import Router from "next/router";

const useWarnBeforeLeave = (shouldWarn: boolean, message: string) => {
  useEffect(() => {
    const routeChangeStart = (url) => {
      if (Router.asPath !== url && shouldWarn && !confirm(message)) {
        Router.events.emit("routeChangeError");
        Router.replace(Router, Router.asPath);
        throw new Error("Abort route change. Please ignore this error.");
      }
    };

    const beforeunload = (e) => {
      if (shouldWarn) {
        e.preventDefault();
        e.returnValue = message;

        return message;
      }
    };

    window.addEventListener("beforeunload", beforeunload);
    Router.events.on("routeChangeStart", routeChangeStart);

    return () => {
      window.removeEventListener("beforeunload", beforeunload);
      Router.events.off("routeChangeStart", routeChangeStart);
    };
  }, [shouldWarn]);
};
export default useWarnBeforeLeave;
