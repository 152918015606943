import React from "react";
import styled from "styled-components";
import { colors } from "@/lib/styles";
import { MOBILE_WIDTH } from "@/lib/constants";
import useWindowSize from "@/hooks/useWindowSize";
import useOwner from "@/hooks/useOwner";
import Checkmark from "../Checkmark";

const Label = styled.label`
  font-size: 21px;
  font-weight: 500;
  color: ${colors.nearBlack};
  margin-bottom: 15px;
  padding: 0 10px;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 12px;
  }
`;
const Error = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${colors.darkGray};
  margin-top: 5px;
  padding: 0 10px;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 12px;
  }
`;

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 0;
  width: 100%;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  position: relative;
`;

const Option = styled.div<{ checked: boolean; pristine: boolean; num: number }>`
  user-select: none;
  font-size: 16px;
  font-weight: 500;
  color: ${(props) =>
    props.pristine || props.checked ? colors.nearBlack : colors.darkGray};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: pre-wrap;
  padding: 15px 20px;
  border-radius: 6px;
  margin: 0 10px;
  flex: 1;
  flex-basis: 100%;
  border: 1px solid
    ${(props) => (props.checked ? colors.nearBlack : colors.lightGray)};
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  text-align: center;
  /* &:first-of-type {
    margin-top: 0;
    padding-top: 0;
  } */
  &:hover {
    background-color: ${colors.nearWhite};
    border: 1px solid ${colors.darkGray};
    color: ${colors.nearBlack};
    cursor: pointer;
  }
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 12px;
  }
`;

type Props = {
  title?: string;
  input: {
    value: number;
    name: string;
    onChange: (value: number) => void;
  };
  meta: {
    error?: string;
  };
  options: {
    title: string;
    value: number;
  }[];
  labelStyle?: Record<string, unknown>;
  inputStyle?: Record<string, unknown>;
  style?: Record<string, unknown>;
};
const RangeInput: React.FC<Props> = (props) => {
  const { input, title, options, labelStyle, inputStyle, meta, style } = props;

  const owner = useOwner();

  const { width } = useWindowSize();

  return (
    <Outer style={style}>
      {title && <Label style={labelStyle}>{title}</Label>}
      <Wrapper data-cy={`input-${input.name}`}>
        {options.map((option, i) => {
          const checked = input.value === i;

          return (
            <Option
              checked={checked}
              pristine={typeof input.value !== "number"}
              key={`multi-select-option-${option.value}`}
              style={{
                ...inputStyle,
                ...(width > MOBILE_WIDTH ? {} : { fontSize: 12 }),
              }}
              onClick={() => {
                input.onChange(i);
              }}
              num={options.length}
              data-cy={"range-input-option"}
            >
              {option.title}
              {checked && <Checkmark size={21} style={{ marginLeft: 8 }} />}
            </Option>
          );
        })}
      </Wrapper>
      {meta?.error && <Error>{meta.error}</Error>}
    </Outer>
  );
};

export default RangeInput;
