import { colors } from "@/lib/styles";
import styled from "styled-components";
import { Step } from "@/models/program";
import VideoIcon from "@/components/icons/VideoIcon";
import DescriptionIcon from "@/components/icons/DescriptionIcon";
import StarIcon from "@/components/icons/StarIcon";
import CheckIcon from "../icons/CheckIcon";
import CaretIcon from "../icons/CaretIcon";
import useOwner from "@/hooks/useOwner";
import PdfIcon from "../icons/PdfIcon";
import ImageIcon from "../icons/ImageIcon";
import BuildIcon from "../icons/BuildIcon";
import useEmbeddedProgram from "@/hooks/useEmbeddedProgram";
import useEmbeddedSessionIndex from "@/hooks/useEmbeddedSessionIndex";
import usePreview from "@/hooks/usePreview";
import useProgram from "@/hooks/useProgram";
import { ownerPath } from "@/lib/path";
import { useRouter } from "next/router";
import ChatIcon from "../icons/ChatIcon";

const SectionWrapper = styled.div<{
  active: boolean;
  completed: boolean;
  color: string;
}>`
  background-color: ${(props) =>
    props.active ? colors.nearWhite : colors.nearWhitePrimary};
  padding: 15px 30px 15px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  &:hover {
    cursor: ${(props) => (!!props.onClick ? "pointer" : "default")};
    background-color: ${(props) =>
      !!props.onClick
        ? colors.nearWhite
        : props.active
        ? colors.nearWhite
        : colors.nearWhitePrimary};
  }
`;

const Wrapper = styled.div<{
  active: boolean;
  completed: boolean;
  color: string;
}>`
  padding: 15px 30px 15px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  background-color: ${(props) =>
    props.active ? colors.nearWhite : colors.white};
  &:hover {
    cursor: ${(props) => (!!props.onClick ? "pointer" : "default")};
    background-color: ${(props) =>
      !!props.onClick
        ? colors.nearWhite
        : props.active
        ? colors.nearWhite
        : colors.nearWhitePrimary};
  }
`;

const SectionTitle = styled.h4<{
  active: boolean;
  completed: boolean;
  color: string;
}>`
  font-size: 16px;
  font-weight: 500;
  color: ${(props) =>
    props.active
      ? colors.nearBlack
      : props.completed
      ? colors.darkGray
      : colors.darkGray};
`;

const Title = styled.h4<{ active: boolean; completed: boolean; color: string }>`
  font-size: 16px;
  font-weight: 400;
  margin-left: 10px;
  color: ${(props) =>
    props.active
      ? colors.nearBlack
      : props.completed
      ? colors.darkGray
      : colors.darkGray};
`;

type Props = {
  step: Step;
  stepIndex: number;
  active?: boolean;
  onClick?: () => void;
  completed?: boolean;
  style?: object;
  depth?: number;
};

export const StepLine: React.FC<Props> = (props) => {
  const { step, stepIndex, active, completed, onClick, depth } = props;

  const embeddedProgram = useEmbeddedProgram();
  const embeddedSessionIndex = useEmbeddedSessionIndex();
  const preview = usePreview();
  const owner = useOwner();
  const program = useProgram();
  const router = useRouter();

  const style = {
    paddingLeft: 20 + depth * 15,
    ...props.style,
  };

  switch (step.type) {
    case "SECTION":
      return (
        <SectionWrapper
          completed={completed}
          active={active}
          color={colors.nearBlack}
          onClick={onClick}
          style={style}
        >
          <SectionTitle
            completed={completed}
            active={active}
            color={colors.nearBlack}
          >
            {step.title}
          </SectionTitle>
          {completed && (
            <CheckIcon
              color={colors.darkGray}
              style={{ position: "absolute", right: 15 }}
            />
          )}
          {active && (
            <CaretIcon
              size={14}
              color={colors.nearBlack}
              style={{
                position: "absolute",
                right: 15,
                transform: "translateY(2px)",
              }}
            />
          )}
        </SectionWrapper>
      );
    case "VIDEO":
      return (
        <Wrapper
          completed={completed}
          active={active}
          color={colors.nearBlack}
          onClick={onClick}
          style={style}
        >
          <VideoIcon
            size={16}
            color={
              active
                ? colors.nearBlack
                : completed
                ? colors.darkGray
                : colors.darkGray
            }
          />
          <Title completed={completed} active={active} color={colors.nearBlack}>
            {step.title}
          </Title>
          {completed && (
            <CheckIcon
              color={colors.darkGray}
              style={{ position: "absolute", right: 15 }}
            />
          )}
          {active && (
            <CaretIcon
              size={14}
              color={colors.nearBlack}
              style={{
                position: "absolute",
                right: 15,
                transform: "translateY(2px)",
              }}
            />
          )}
        </Wrapper>
      );
    case "IMAGE":
      return (
        <Wrapper
          completed={completed}
          active={active}
          color={colors.nearBlack}
          onClick={onClick}
          style={style}
        >
          <ImageIcon
            size={16}
            color={
              active
                ? colors.nearBlack
                : completed
                ? colors.darkGray
                : colors.darkGray
            }
          />
          <Title completed={completed} active={active} color={colors.nearBlack}>
            {step.title}
          </Title>
          {completed && (
            <CheckIcon
              color={colors.darkGray}
              style={{ position: "absolute", right: 15 }}
            />
          )}
          {active && (
            <CaretIcon
              size={14}
              color={colors.nearBlack}
              style={{
                position: "absolute",
                right: 15,
                transform: "translateY(2px)",
              }}
            />
          )}
        </Wrapper>
      );
    case "PDF":
      return (
        <Wrapper
          completed={completed}
          active={active}
          color={colors.nearBlack}
          onClick={onClick}
          style={style}
        >
          <PdfIcon
            size={16}
            color={
              active
                ? colors.nearBlack
                : completed
                ? colors.darkGray
                : colors.darkGray
            }
          />
          <Title completed={completed} active={active} color={colors.nearBlack}>
            {step.title}
          </Title>
          {completed && (
            <CheckIcon
              color={colors.darkGray}
              style={{ position: "absolute", right: 15 }}
            />
          )}
          {active && (
            <CaretIcon
              size={14}
              color={colors.nearBlack}
              style={{
                position: "absolute",
                right: 15,
                transform: "translateY(2px)",
              }}
            />
          )}
        </Wrapper>
      );
    case "CHAT":
      return (
        <Wrapper
          completed={completed}
          active={active}
          color={colors.nearBlack}
          onClick={onClick}
          style={style}
        >
          <ChatIcon
            size={16}
            color={
              active
                ? colors.nearBlack
                : completed
                ? colors.darkGray
                : colors.darkGray
            }
          />
          <Title completed={completed} active={active} color={colors.nearBlack}>
            {step.title}
          </Title>
          {completed && (
            <CheckIcon
              color={colors.darkGray}
              style={{ position: "absolute", right: 15 }}
            />
          )}
          {active && (
            <CaretIcon
              size={14}
              color={colors.nearBlack}
              style={{
                position: "absolute",
                right: 15,
                transform: "translateY(2px)",
              }}
            />
          )}
        </Wrapper>
      );
    case "TEXT":
      return (
        <Wrapper
          completed={completed}
          active={active}
          color={colors.nearBlack}
          onClick={onClick}
          style={style}
        >
          <DescriptionIcon
            size={16}
            color={
              active
                ? colors.nearBlack
                : completed
                ? colors.darkGray
                : colors.darkGray
            }
          />
          <Title completed={completed} active={active} color={colors.nearBlack}>
            {step.title}
          </Title>
          {completed && (
            <CheckIcon
              color={colors.darkGray}
              style={{ position: "absolute", right: 15 }}
            />
          )}
          {active && (
            <CaretIcon
              size={14}
              color={colors.nearBlack}
              style={{
                position: "absolute",
                right: 15,
                transform: "translateY(2px)",
              }}
            />
          )}
        </Wrapper>
      );
    case "SURVEY":
      return (
        <Wrapper
          completed={completed}
          active={active}
          color={colors.nearBlack}
          onClick={onClick}
          style={style}
        >
          <StarIcon
            size={16}
            color={
              active
                ? colors.nearBlack
                : completed
                ? colors.darkGray
                : colors.darkGray
            }
          />
          <Title completed={completed} active={active} color={colors.nearBlack}>
            {step.title}
          </Title>
          {completed && (
            <CheckIcon
              color={colors.darkGray}
              style={{ position: "absolute", right: 15 }}
            />
          )}
          {active && (
            <CaretIcon
              size={14}
              color={colors.nearBlack}
              style={{
                position: "absolute",
                right: 15,
                transform: "translateY(2px)",
              }}
            />
          )}
        </Wrapper>
      );
    case "PROGRAM":
      return (
        <>
          <Wrapper
            completed={completed}
            active={false}
            color={colors.nearBlack}
            onClick={onClick}
            style={style}
          >
            <BuildIcon
              size={16}
              color={
                active
                  ? colors.nearBlack
                  : completed
                  ? colors.darkGray
                  : colors.darkGray
              }
              style={{ transform: "translateY(2px)" }}
            />
            <Title
              completed={completed}
              active={active}
              color={colors.nearBlack}
            >
              {step.title}
            </Title>
            {completed && (
              <CheckIcon
                color={colors.darkGray}
                style={{ position: "absolute", right: 15 }}
              />
            )}
          </Wrapper>
          {active &&
            embeddedProgram?.steps?.map((step, index) => (
              <StepLine
                key={`embedded-step-${index}`}
                step={step}
                stepIndex={index}
                depth={1}
                completed={index < embeddedSessionIndex}
                onClick={
                  preview
                    ? () =>
                        router.replace(
                          ownerPath(owner, `program/${program?.id}/preview`, {
                            i: Number(`${stepIndex}.${index}`),
                          })
                        )
                    : undefined
                }
                active={index === embeddedSessionIndex}
              />
            ))}
        </>
      );
  }
};
export default StepLine;
