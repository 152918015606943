import { colors } from "@/lib/styles";
import styled from "styled-components";
import { ChatStep } from "@/models/program";
import { ChatOpenAI } from "langchain/chat_models/openai";

import { MEDIUM_MAX_WIDTH, MOBILE_WIDTH } from "@/lib/constants";
import { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import toast from "react-hot-toast";
import Messages from "./Messages";
import Send from "./Send";
import { CallbackManager } from "langchain/callbacks";
import {
  AIMessagePromptTemplate,
  ChatPromptTemplate,
  HumanMessagePromptTemplate,
  MessagesPlaceholder,
  SystemMessagePromptTemplate,
} from "langchain/prompts";
import { ConversationChain } from "langchain/chains";
import { BufferMemory } from "langchain/memory";
import { OPENAI_API_KEY } from "@/env";
import * as Sentry from "@sentry/nextjs";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 1;
  height: 100%;
  max-height: 100%;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  border: 1px solid ${colors.lightGray};
  border-radius: 10px;
  height: 100%;
  width: 100%;
  flex: 1;
  max-width: ${MEDIUM_MAX_WIDTH}px;
  overflow-y: hidden;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    border: none;
  }
`;

type Props = {
  step: ChatStep;
  setDone: (done: boolean) => void;
};

export const BaseChat: React.FC<Props> = (props) => {
  const { step, setDone } = props;

  const [messages, setMessages] = useState<
    { text: string; from: "ai" | "user" }[]
  >([]);
  const chainRef = useRef<ConversationChain>();

  // setup ai
  useEffect(() => {
    try {
      const chat = new ChatOpenAI({
        openAIApiKey: OPENAI_API_KEY,
        temperature: 0,
        streaming: true,
        callbackManager: CallbackManager.fromHandlers({
          async handleLLMStart() {
            console.log("START");
            setMessages((messages) => [...messages, { text: "", from: "ai" }]);
          },
          async handleLLMNewToken(token: string) {
            console.log(token);
            setMessages((messages) => [
              ...messages.slice(0, messages.length - 1),
              { text: messages.slice(-1)[0].text + token, from: "ai" },
            ]);
          },
          async handleLLMEnd() {
            console.log("END");
          },
        }),
      });

      const initMessages = [
        SystemMessagePromptTemplate.fromTemplate(step.prompt),
        AIMessagePromptTemplate.fromTemplate(step.firstMessage),
        new MessagesPlaceholder("history"),
        HumanMessagePromptTemplate.fromTemplate("{input}"),
      ];
      const chatPrompt = ChatPromptTemplate.fromPromptMessages(initMessages);

      const chain = new ConversationChain({
        memory: new BufferMemory({
          returnMessages: true,
          memoryKey: "history",
        }),
        prompt: chatPrompt,
        llm: chat,
      });
      chainRef.current = chain;

      setMessages([{ text: step.firstMessage, from: "ai" }]);
    } catch (e) {
      console.error(e);
    }
  }, []);

  // send message mutation
  const sendMessageMutation = useMutation(
    async (message: string) => {
      setMessages((messages) => [...messages, { text: message, from: "user" }]);
      await chainRef.current?.call({ input: message });
    },
    {
      onError: (error) => {
        Sentry.captureException(error);
        toast.custom("Could not send message - try refreshing the page");
      },
    }
  );

  return (
    <>
      <Wrapper>
        <Content data-cy={"step-chat-content"}>
          <Messages
            messages={messages.map((message) => ({
              text: message.text,
              from: "ai",
            }))}
          />
          <Send sendMessageMutation={sendMessageMutation} />
        </Content>
      </Wrapper>
    </>
  );
};

export default BaseChat;
